import React, { ChangeEvent, useState, FormEvent, useEffect } from 'react';
import { useAppState } from '../../state';

import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { ReactComponent as GoogleLogo } from './google-logo.svg';
// import { ReactComponent as TwilioLogo } from './twilio-logo.svg';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { isMobile } from '../../utils';

import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';

import logoConecta from './logo-conecta.svg';
import { tou } from './tou';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    background: '#FFF',
    position: 'relative',
  },
  header: {
    height: 64,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px -2px 7px 0px rgba(0,0,0,0.75)',
    padding: '10px 0',
    boxSizing: 'content-box',
  },
  twilioLogo: {
    width: '55%',
    display: 'block',
  },
  videoLogo: {
    width: '100%',
    padding: '2.4em 0 2.1em',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '2em',
    background: 'white',
    color: 'black',
    minWidth: '20%',
  },
  button: {
    color: '#FFF',
    background: '#4267B2',
    margin: '1.6em 0 0.7em',
    textTransform: 'none',
    '&:hover': {
      background: '#4267B2',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  footer: {
    background: '#F7F7F7',
    borderTop: '1px solid #eee',
    height: 64,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& a': {
      color: '#4267B2',
      textDecoration: 'none',
      fontSize: 13,
      '&:hover': {
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
    },
  },
  reloadSpan: {
    backgroundColor: '#0eb14b',
    color: '#FFF',
    borderRadius: 4,
    border: 0,
    padding: '3px 6px',
  },
});

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

export default function LoginPage() {
  const { uuid } = useParams<{ uuid: string }>();
  const classes = useStyles();
  const { signIn, user } = useAppState();
  const [hasCaptcha, setHasCaptcha] = useState(false);
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [passcode, setPasscode] = useState('');
  // const [isInputFocused, setIsInputFocused] = useState(false);
  // const [email, setEmail] = useState('');
  const [authError, setAuthError] = useState<Error | null>(null);
  const isAuthEnabled = Boolean(process.env.REACT_APP_SET_AUTH);
  const isCaptchaEnabled = Boolean(process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY);
  const whatsAppHref = `https://web.whatsapp.com/send?phone=5511976005673&text=Olá, preciso de ajuda na consulta de ID ${uuid}`;
  const whatsappString = whatsAppHref.replace(' ', '%20');
  const [hasAcceptedTou, setHasAcceptedTou] = useState(false);
  const [openTou, setOpenTou] = useState(false);

  const [partnerLogo, setPartnerLogo] = useState('');
  const [isLogoLoading, setIsLogoLoading] = useState(true);

  const login = () => {
    setAuthError(null);
    signIn?.(passcode)
      .then(() => {
        history.replace(location?.state?.from || { pathname: '/' });
      })
      .catch(err => setAuthError(err));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };

  const handleCustomSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // if (!email) {
    //   Swal.fire('Aviso', 'Você precisa informar o seu e-mail para prosseguir', 'warning');
    //   return;
    // }

    if (!uuid) {
      history.push('/not_found');
      return;
    }

    const endpoint = `${process.env.REACT_APP_TOKEN_ENDPOINT}/telemedicine/agenda/${uuid}/token`;

    fetch(`${endpoint}`)
      .then(res => {
        if (res.status < 400) {
          res.json().then(response => {
            // take the latest object from array
            if (response && response.length) {
              const token = response[response.length - 1].token;
              localStorage.setItem('@token', token);
              localStorage.setItem('@uuid', uuid);

              history.push('/video');
              return;
            }
          });
        } else if (res.status === 400) {
          // in this case, room is offline
          localStorage.setItem('@uuid', uuid);
          // localStorage.setItem('@email', email);
          history.push('/entry_page');
          return;
        } else if (res.status === 403) {
          Swal.fire(
            'Aviso',
            'O e-mail informado não coincide com o cadastrado para essa consulta. Confira o e-mail e tente novamente.',
            'error'
          );
          return;
        } else {
          res.json().then(() => {
            localStorage.setItem('@uuid', uuid);
            history.push('/finish');
            return;
          });
        }
      })
      .catch(err => {
        Swal.fire('Aviso', 'Não foi possível acessar a sala. Por favor, tente novamente.', 'error');
        console.log('Externo');
        console.warn(err);
      });
  };

  const handleTouChange = (event: any) => {
    setHasAcceptedTou(event.target.checked);
  };

  if (user || !isAuthEnabled || localStorage.getItem('@token')) {
    history.replace('/entry_page');
  }

  useEffect(() => localStorage.clear(), []);

  useEffect(() => {
    if (!uuid) {
      history.push('/not_found');
      return;
    }

    const endpoint = `${process.env.REACT_APP_TOKEN_ENDPOINT}/telemedicine/agenda/${uuid}`;

    fetch(`${endpoint}`)
      .then(res => {
        if (res.status < 400) {
          res.json().then(response => {
            // take the latest object from array
            console.log({ response });
            if (response?.health_place_partner?.uuid) {
              let logoUrl =
                'https://s3-us-west-2.amazonaws.com/static.drconecta.com.br/images/mail/health_place_partner/';
              logoUrl = logoUrl + response.health_place_partner.uuid + '.png';

              setPartnerLogo(logoUrl);

              response.health_place_partner.logo_url = logoUrl;

              localStorage.setItem('@partner', JSON.stringify(response.health_place_partner));
            }

            setIsLogoLoading(false);
          });
        } else if (res.status === 400) {
          // in this case, room is offline
          localStorage.setItem('@uuid', uuid);
          // localStorage.setItem('@email', email);
          history.push('/entry_page');
          return;
        } else if (res.status === 403) {
          Swal.fire(
            'Aviso',
            'O e-mail informado não coincide com o cadastrado para essa consulta. Confira o e-mail e tente novamente.',
            'error'
          );
          setIsLogoLoading(false);
          return;
        } else {
          res.json().then(() => {
            localStorage.setItem('@uuid', uuid);
            history.push('/finish');
            return;
          });
        }
      })
      .catch(err => {
        Swal.fire('Aviso', 'Não foi possível acessar a sala. Por favor, tente novamente.', 'error');
        console.log('Externo');
        console.warn(err);
        setIsLogoLoading(false);
      });
  }, [history, uuid]);

  return (
    <ThemeProvider theme={theme}>
      <Grid container justify="center" alignItems="flex-start" className={classes.container}>
        <header className={classes.header}>
          {!isLogoLoading && <img src={partnerLogo || logoConecta} alt="logo" />}
        </header>
        <div
          style={{
            display: 'flex',
            minHeight: 600,
            height: 'calc(100vh - 148px)',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: 0,
            marginBottom: 20,
          }}
        >
          <div style={{ width: '70%', textAlign: 'center', marginBottom: '25px' }}>
            <p>
              Para uma melhor experiência recomendamos usar esse serviço pelo browser <b>Chrome</b>.<br />
              <br />
              Caso tenha dificuldade de ver ou ouvir o médico no início ou durante a consulta, clique no botão{' '}
              <span className={classes.reloadSpan}>RECARREGAR</span> que estará no topo da página.
            </p>
          </div>

          <Paper className={classes.paper} elevation={4}>
            {process.env.REACT_APP_SET_AUTH === 'firebase' && (
              <Button variant="contained" className={classes.button} onClick={login} startIcon={<GoogleLogo />}>
                Sign in with Google
              </Button>
            )}

            {process.env.REACT_APP_SET_AUTH === 'passcode' && (
              <form onSubmit={handleSubmit}>
                <Grid container alignItems="center" direction="column">
                  <TextField
                    id="input-passcode"
                    label="Passcode"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setPasscode(e.target.value)}
                    type="password"
                  />
                  <div>
                    {authError && (
                      <Typography variant="caption" className={classes.errorMessage}>
                        <ErrorOutlineIcon />
                        {authError.message}
                      </Typography>
                    )}
                  </div>
                  <Button variant="contained" className={classes.button} type="submit" disabled={!passcode.length}>
                    Enviar
                  </Button>
                </Grid>
              </form>
            )}

            {process.env.REACT_APP_SET_AUTH === 'custom_passcode' && (
              <>
                <p style={{ color: '#4A4A4A', fontSize: 16 }}>Por favor, leia os termos de uso antes de iniciar.</p>
                <form onSubmit={handleCustomSubmit}>
                  <Grid container alignItems="center" direction="column" style={{ width: '90%' }}>
                    {/* <TextField
                      style={{ minWidth: 300, marginBottom: 15 }}
                      id="input-passcode"
                      label="E-mail"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                      onFocus={() => setIsInputFocused(true)}
                      onBlur={() => setIsInputFocused(false)}
                      type="email"
                    /> */}
                    {isCaptchaEnabled && (
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}
                        onChange={(val: any) => setHasCaptcha(val)}
                      />
                    )}
                    <div>
                      {authError && (
                        <Typography variant="caption" className={classes.errorMessage}>
                          <ErrorOutlineIcon />
                          {authError.message}
                        </Typography>
                      )}
                    </div>
                    <div>
                      <br />
                      <Checkbox
                        checked={hasAcceptedTou}
                        onChange={handleTouChange}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />{' '}
                      Aceito os{' '}
                      <Button onClick={() => setOpenTou(true)} size="small" color="primary">
                        termos de uso
                      </Button>
                    </div>
                    <Button
                      variant="contained"
                      className={classes.button}
                      type="submit"
                      disabled={(isCaptchaEnabled && !hasCaptcha) || !hasAcceptedTou}
                    >
                      Enviar
                    </Button>
                  </Grid>
                </form>
              </>
            )}
          </Paper>
        </div>

        {/* TOU */}
        <Dialog
          open={openTou}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenTou(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {'TERMOS E CONDIÇÕES DE USO DA PLATAFORMA DR. CONECTA PARA TELEMEDICINA'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ whiteSpace: 'pre-line' }} id="alert-dialog-slide-description">
              {tou}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenTou(false);
                setHasAcceptedTou(false);
              }}
              color="primary"
            >
              Não aceito
            </Button>
            <Button
              onClick={() => {
                setOpenTou(false);
                setHasAcceptedTou(true);
              }}
              color="primary"
            >
              Aceito
            </Button>
          </DialogActions>
        </Dialog>
        {/* END OF TOU */}

        <footer className={classes.footer}>
          <a
            href={isMobile ? 'mailto:atendimento@drconecta.com.br' : whatsappString}
            target="_blank"
            rel="noopener noreferrer"
          >
            Precisa de ajuda?
          </a>
        </footer>
      </Grid>
    </ThemeProvider>
  );
}
