import React, { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import CircularProgress from '@material-ui/core/CircularProgress';

import logoConecta from './logo-conecta.svg';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    background: '#FFF',
    position: 'relative',
  },
  header: {
    height: 64,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px -2px 7px 0px rgba(0,0,0,0.75)',
    padding: '10px 0',
    boxSizing: 'content-box',
  },
});

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

export default function LoginPage() {
  const { token } = useParams();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      Swal.fire('Aviso', 'Houve um problema com o seu token de acesso, tente novamente.', 'error');
      return;
    }
    localStorage.setItem('@token', token);
    history.push('/video');
    return;
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container justify="center" alignItems="flex-start" className={classes.container}>
        <header className={classes.header}>
          <img src={logoConecta} alt="logo" />
        </header>
        <CircularProgress />
      </Grid>
    </ThemeProvider>
  );
}
